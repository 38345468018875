import React, { useEffect, useRef, useState } from 'react';

import { PageProps } from 'gatsby';
import CreatorListItem from '../components/CreatorListItem';
import NavigationBar from '../components/NavigationBar';
import Layout, { ContentFill } from '../components/Layout';
import { css } from '@emotion/react';
import { useInView } from 'react-cool-inview';
import { fetchCreators } from '../api';
import EmptyState from '../components/EmptyState';
import LoadingSpinner from '../components/LoadingSpinner';
import FloatingUpButton from '../components/FloatingUpButton';
import useDidUpdateEffect from '../hooks/useDidUpdateEffect';

export default function Creators({ location }: PageProps) {
  const keyword =
    new URLSearchParams(location.search).get('keyword') ?? undefined;

  const [creatorResults, setCreatorResults] = useState<CreatorResponse>();

  const [page, setPage] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!keyword) return;
    fetchCreators({ keyword, page: 0 + 1, take }).then(setCreatorResults);
  }, [keyword]);

  useDidUpdateEffect(() => {
    (async () => {
      if (!keyword) return;
      const creatorResponse = await fetchCreators({
        keyword,
        page: page + 1,
        take,
      });
      setCreatorResults(
        (creatorResults) =>
          creatorResults && {
            ...creatorResults,
            creators: creatorResults.creators.concat(creatorResponse.creators),
          }
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const { observe } = useInView({
    onEnter: () => {
      if (creatorResults) {
        setPage((page) => (page < creatorResults.totalPages ? page + 1 : page));
      }
    },
  });

  const isLoading = !creatorResults;

  return (
    <Layout>
      <NavigationBar type="normal" />
      <ContentFill ref={ref}>
        <ul css={listStyle}>
          {creatorResults?.creators.map((creator, index) => (
            <li key={creator.id}>
              <CreatorListItem
                creator={creator}
                ref={
                  index === creatorResults.creators.length - 1
                    ? observe
                    : undefined
                }
              />
            </li>
          ))}
        </ul>
        {creatorResults?.count === 0 && <EmptyState />}
        {isLoading && <LoadingSpinner />}
        <FloatingUpButton scrollContainerRef={ref} />
      </ContentFill>
    </Layout>
  );
}

const take = 80;

const listStyle = css`
  list-style: none;

  > li:not(:last-of-type) {
    margin-bottom: var(--large);
  }
`;
